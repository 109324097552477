import React from 'react';
import { AETNA_SLUG } from '../../../constants';
import { Text, Link, BulletList } from '../../';

const textVariant = 'p2a';

const subTitleVariant = 'p2b';

const containerClassName = 'mb-sm';

const subTitleClassName = 'mb-tiny';

export const availabilityCopy =
  "Parsley Health is available to US residents however not yet available in all 50 states. If members live in the United States but aren't located near one of our physical centers in NYC or LA, they can sign up for a membership and work with us completely online via video visits with their clinician and health coach. The quality of care is the same online and in-person, but there are a few differences:";

export default {
  headline: 'Still have questions?',
  questions: [
    {
      id: '1',
      question: 'What’s included in my membership plan?',
      answer: (
        <div className={containerClassName}>
          <Text variant={subTitleVariant} className={subTitleClassName}>
            Our 12-month Complete Care membership is an annual plan focused on
            providing holistic care that includes:
          </Text>
          <BulletList
            items={[
              {
                content: '5 in-depth medical visits with your clinician to:',
                subItems: [
                  'Follow up on your medical questionnaires to understand your entire health history, and current health concerns, lifestyle, and diet.',
                  'Give you physical or virtual visual exams. These will help your clinician determine your risk factors, identify signs of nutrient deficiencies or toxicities, and give you recommendations for advanced testing and blood work.',
                  'Go over your personalized health plan with you, review results from your testing, discuss your progress, and work with you to adjust your plan to optimize your wellbeing.'
                ]
              },
              '5 visits with your health coach to address your recommended lifestyle changes, set achievable goals, and adjust your health plan based on how you’re responding to changes.',
              'A personalized health plan that addresses your lifestyle, nutrition, sleep, movement, and mental wellbeing.',
              'Access to advanced diagnostic testing for hormones, genetics, and more.',
              'Convenient online scheduling so you can find a time that works best for you.',
              <Text variant={textVariant}>
                Unlimited messaging with your Care Team using the{' '}
                <Link
                  underline
                  variant={textVariant}
                  destination="https://my.parsleyhealth.com/"
                >
                  My Parsley Health portal
                </Link>
                , so you can stay connected in between visits.
              </Text>,
              'Prescriptions for medications and supplements all in one place so you don’t have to chase your providers.',
              <Text variant={textVariant}>
                20% discounted membership rate at our online{' '}
                <Link
                  underline
                  variant={textVariant}
                  destination="https://store.parsleyhealth.com/"
                >
                  supplement store
                </Link>
                .
              </Text>,
              <Text variant={textVariant}>
                Exclusive deals on Parsley-vetted food, products, and services
                from our favorite wellness partners found{' '}
                <Link underline variant={textVariant} destination="/perks/">
                  here
                </Link>
                .
              </Text>
            ]}
          />
        </div>
      )
    },
    {
      id: '2',
      question:
        'Prescriptions for medications and supplements all in one place so you don’t have to chase your providers.',
      answer: (
        <div className={containerClassName}>
          <Text variant={subTitleVariant} className={subTitleClassName}>
            {availabilityCopy}
          </Text>
          <BulletList
            items={[
              'Center members are still required to establish care in the state of New York or California, which can be done virtually.',
              'Virtual-only members cannot receive controlled substance or scheduled medication prescriptions from Parsley clinicians. We don’t typically provide controlled substances, but can for in-person members.',
              'Virtual-only members cannot receive physical examinations for documentation from Parsley clinicians (we do a modified physical virtual exam, but will not submit documentation or paperwork).',
              'All of our care is consultative.'
            ]}
          />
          <Text variant={textVariant}>
            If you live near one of our centers in Los Angeles or New York City,
            you have the option of in-person appointments. If you don’t live
            nearby or would prefer the convenience of virtual appointments, you
            can talk to your Care Team through secure, face-to-face video on
            your phone, tablet, or computer. To see if we are available in your
            state, please select a plan and input your zip code.
          </Text>
        </div>
      )
    },
    {
      id: '3',
      question: 'What if I change my mind about my membership?',
      answer: (
        <div className={containerClassName}>
          <Text variant={textVariant}>
            <u>If you change your mind within 24 hours</u> of signing up, we
            will be sure to issue a full refund.
          </Text>
          <Text variant={textVariant}>
            <u>
              If you cancel after 48 hours but before your first medical visit
            </u>
            , you may receive a refund, less a $150 service fee.
          </Text>
          <Text variant={subTitleVariant} className={subTitleClassName}>
            <u>If you cancel after 48 hours after your first medical visit</u>,
            you will only be responsible for paying the costs of services you
            used. We will collect the balance between membership fees paid and
            fees associated with visits used according to the following
            schedule:
          </Text>
          <BulletList
            items={[
              '$375 per 60-minute clinician visit',
              '$150 per 30-minute clinician visit',
              '$100 per health coach visit'
            ]}
          />
        </div>
      )
    },
    {
      id: '4',
      question: 'Does insurance cover Parsley Health services?',
      answer: (
        <div className={containerClassName}>
          <Text variant={textVariant} className="mb-xxs">
            While we’re currently out of network with most insurance plans, we
            offer in-network membership for people who live in New York and are
            directly insured by Aetna NY (learn more{' '}
            <Link
              underline
              variant={textVariant}
              destination={`/${AETNA_SLUG}/`}
            >
              here
            </Link>
            ). We’re working hard to expand access to in-network memberships to
            make Parsley Health’s holistic medical care more widely accessible.
            In the meantime, you may be able to use out-of-network benefits for
            doctor visits. If you’re a current member and have questions about
            in-network plans, message us{' '}
            <Link
              underline
              variant={textVariant}
              destination="https://my.parsleyhealth.com/login?redirect=/messages/"
            >
              here.
            </Link>{' '}
            Not a current member but want to learn more? Schedule a call with us{' '}
            <Link underline variant={textVariant} destination="/contact/">
              here.
            </Link>
          </Text>
          <Text variant={textVariant}>
            Members with out-of-network coverage typically receive a
            reimbursement of 40-70% on clinical fees. Make sure to call the
            member services phone number on the back of your insurance card to
            learn more about your potential out-of-network coverage.
          </Text>
          <Text variant={textVariant}>
            After each doctor visit, you can ask your medical team to prepare a
            medical invoice or “superbill” that details the information your
            insurance company needs to process your claim, including CPT codes
            for services performed, as well as ICD-10 codes for diagnoses.
            Afterward, you’ll need to submit the superbill to your insurance
            company and follow their specific claim process
          </Text>
          <Text variant={textVariant}>
            We know this can be a lot, so if you have any questions while going
            through the process, you can always reach out to your Care Team for
            support.
          </Text>
        </div>
      )
    },
    {
      id: '5',
      question: 'What’s NOT included in my membership plan?',
      answer: (
        <div className={containerClassName}>
          <Text variant={subTitleVariant} className={subTitleClassName}>
            There are some costs that are not included in your membership and
            may require out-of-pocket expenses:
          </Text>
          <BulletList
            items={[
              'Lab tests: Many tests are covered partially or fully with insurance, but some may require a copay or may not be covered at all. Specialty tests (like stool, urine, and saliva) are not included in your membership.',
              'Health care services outside of what is specified above such as vaccinations, urgent care, MRIs, etc.',
              'Non-medical services recommended by Parsley, such as acupuncture, fitness classes, medications (both prescription and over-the-counter), and supplements.'
            ]}
          />
        </div>
      )
    }
  ]
};
