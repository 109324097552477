import React from 'react';
import { baseSanityNode } from '../base-sanity-node';
import { HighlightedText } from '../..';

const content = {
  eyebrow: '',
  headline: (
    <>
      A{' '}
      <HighlightedText
        variant="underline3"
        className="ha2-highlighted-underline-3"
      >
        science-backed,
      </HighlightedText>{' '}
      clinically-proven approach.
    </>
  ),
  description:
    'Parsley Health uses a holistic approach to medicine to get the full picture of your health and prescribe a plan for long-term healing.',
  items: [
    {
      id: 1,
      title: 'We get to (really) know you.',
      description:
        'We dedicate time to understanding your genetics, history, lifestyle, and goals. Combined with advanced diagnostic testing other doctors don’t run, you get in-depth information about your health and body.',
      image: {
        asset: {
          ...baseSanityNode,
          url:
            'https://cdn.sanity.io/images/mur2ep80/production/f56225138e1cb061e8592f791aa093588e0588da-2800x2800.png'
        },
        alt: 'alt'
      },
      firstCard: true
    },
    {
      id: 2,
      title: 'We treat the root cause.',
      description:
        'Applying the best tools and techniques of conventional medicine alongside lifestyle changes, nutrition, and supplements—we target the drivers of disease.',
      image: {
        asset: {
          ...baseSanityNode,
          url:
            'https://cdn.sanity.io/images/mur2ep80/production/7476fe3fc52be1241ec03e2d101cebf6148b1e92-2800x2800.png'
        },
        alt: 'alt'
      },
      firstCard: false
    },
    {
      id: 3,
      title: 'We stay by your side.',
      description:
        'Via 10 annual check-ins with your doctor and health coach and unlimited messaging throughout your membership, you get support year-round.',
      image: {
        asset: {
          ...baseSanityNode,
          url:
            'https://cdn.sanity.io/images/mur2ep80/production/b81b4f75f330e02c44f6c9d500fa9182e1f80250-2800x2800.png'
        },
        alt: 'alt'
      },
      firstCard: false
    },
    {
      id: 4,
      title: 'So that one year = a big difference.',
      description:
        'Parsley Health is a team effort. By partnering with our members, more than 80% of people see an improvement in their symptoms within the first year.',
      image: {
        asset: {
          ...baseSanityNode,
          url:
            'https://cdn.sanity.io/images/mur2ep80/production/b9f57751eaf89fc5151ac0ae08287929e114a73a-2800x2800.png'
        },
        alt: 'alt'
      },
      firstCard: false
    }
  ]
};

export default content;
