import React from 'react';
import {
  BlockContent,
  Layout,
  Module,
  HalfAndHalf,
  TextLockup,
  Text,
  Button,
  DataListAndChart,
  Faq,
  StandardCarousel,
  ProcessCarousel,
  Structured
} from '../redesign';

import processCarouselContent from '../redesign/content/why-it-works/process-carousel-data';
import ourDoctorsContent from '../redesign/content/our-doctors/our-doctors';
import whyPatientsLoveUsContent from '../redesign/content/why-it-works/why-patients-love-us.json';
import whatWeTreatContent from '../redesign/content/what-we-treat/what-we-treat.json';
import whyMembershipContent from '../redesign/content/why-it-works/why-membership.json';
import faqsContent from '../redesign/content/why-it-works/faqs';
import { default as SEO } from '../components/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import { SanityFaqList } from '../../graphql-types';
interface QueryData {
  sanityFaqList: SanityFaqList;
}

export default function WhyItWorksPage() {
  // static query to get all faq data
  const faqs: QueryData = useStaticQuery(graphql`
    query WhyItWorksFaqPageQuery {
      sanityFaqList(sanityTitle: { eq: "Why it works page" }) {
        items {
          id
          heading
          _rawBody
        }
      }
    }
  `);

  const processedFaqs = faqs.sanityFaqList.items.map(faq => ({
    id: faq.id,
    question: faq.heading,
    answer: <BlockContent blocks={faq._rawBody} />
  }));

  return (
    <Layout theme="ivory">
      <SEO
        pageTitle="What Is Parsley Health? | Assessment & Membership | Parsley Health"
        pageDescription="What is Parsley Health? Learn all about our special approach to functional medicine, holistic healthcare assessments & membership benefits."
      />
      <Module centered>
        <div className="pt-lg">
          <TextLockup alignment="center">
            {processCarouselContent.headline && (
              <Text
                variant="h2a"
                color="dark-green"
                className="text-lockup-headline mb-md"
              >
                {processCarouselContent.headline}
              </Text>
            )}
            {processCarouselContent.description && (
              <Text
                variant="p2a"
                color="dark-green"
                className="text-lockup-description"
              >
                {processCarouselContent.description}
              </Text>
            )}
          </TextLockup>
        </div>
        <ProcessCarousel slides={processCarouselContent.items} />
      </Module>
      <Module>
        <Structured
          heading={ourDoctorsContent.headline}
          items={ourDoctorsContent.items}
          alignment="center"
          cta={ourDoctorsContent.cta}
        />
      </Module>
      <Module>
        <DataListAndChart
          eyebrow={whyPatientsLoveUsContent.eyebrow}
          headline={whyPatientsLoveUsContent.headline}
          items={whyPatientsLoveUsContent.items}
        />
      </Module>
      <Module>
        <StandardCarousel
          eyebrow={whatWeTreatContent.eyebrow}
          heading={whatWeTreatContent.heading}
          slides={whatWeTreatContent.items}
        />
      </Module>
      <Module>
        <HalfAndHalf
          image={whyMembershipContent.image}
          bgColor="gray-nurse"
          imagePosition="right"
          paddingY={false}
          contentClassNames="px-lg"
          inset
          fullImage
        >
          <TextLockup alignment="center">
            {whyMembershipContent.eyebrow && (
              <Text variant="p3b" className="hero-eyebrow mb-xs">
                {whyMembershipContent.eyebrow}
              </Text>
            )}
            <Text variant="h1a" className="hero-headline mb-xs">
              {whyMembershipContent.headline}
            </Text>
            {whyMembershipContent.description && (
              <Text variant="p2a" className="hero-description mb-xs">
                {whyMembershipContent.description}
              </Text>
            )}
            <div className="inline-flex-center hero-buttons-container">
              <div className="text-lockup-buttons-container">
                {whyMembershipContent.primaryCta && (
                  <Button
                    color="sage"
                    variant="filled"
                    className="hero-primary-button"
                    destination={whyMembershipContent.primaryCta.destination}
                    trackingLabel={
                      whyMembershipContent.primaryCta.ctaTrackingLabel
                    }
                  >
                    {whyMembershipContent.primaryCta.label}
                  </Button>
                )}
                {whyMembershipContent.secondaryCta && (
                  <Button
                    variant="outlined"
                    color="darkGreen"
                    destination={whyMembershipContent.secondaryCta.destination}
                    trackingLabel={
                      whyMembershipContent.secondaryCta.ctaTrackingLabel
                    }
                  >
                    {whyMembershipContent.secondaryCta.label}
                  </Button>
                )}
              </div>
            </div>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module>
        <Faq
          faqs={processedFaqs}
          initialExpanded={0}
          heading={faqsContent.headline}
        />
      </Module>
    </Layout>
  );
}
